'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const SvgCar4 = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      d="M23.845 17.67c-1.090-0.002-2.073 0.653-2.492 1.659s-0.189 2.166 0.581 2.937c0.77 0.771 1.929 1.003 2.936 0.586s1.664-1.399 1.664-2.489c0-1.486-1.203-2.691-2.689-2.693zM20.074 20.763v1.348h-8.030v-1.348h-0.014c0.007-0.133 0.007-0.266 0-0.4 0-2.028-1.659-3.671-3.707-3.671s-3.707 1.644-3.707 3.671c0.001 0.13-0.015 1.611 0 1.741h-1.435c-3.236-1.042-3.431-3.831 0.959-8.575 2.75-2.265 3.056-3.108 9.326-3.108s5.605 4.16 8.433 4.137c2.828-0.023 7.939 2.341 9.128 3.291 1.006 0.803 0.327 1.092 0.293 3.596l-3.812 0.701c0-0.127-0.013-1.656-0.013-1.783 0.049-1.342-0.647-2.603-1.813-3.289s-2.617-0.685-3.783 0c-1.166 0.685-1.861 1.946-1.813 3.289-0.007 0.133-0.007 0.266 0 0.4h-0.013zM23.845 21.765c-0.779 0-1.41-0.631-1.41-1.41s0.631-1.41 1.41-1.41c0.779 0 1.41 0.631 1.41 1.41s-0.631 1.41-1.41 1.41zM8.241 17.67c1.486 0.003 2.689 1.208 2.689 2.693 0 1.090-0.657 2.072-1.664 2.489s-2.166 0.185-2.936-0.586c-0.77-0.771-0.999-1.931-0.581-2.937s1.402-1.661 2.492-1.659zM8.241 21.765c0.779 0 1.41-0.631 1.41-1.41s-0.631-1.41-1.41-1.41c-0.779 0-1.41 0.631-1.41 1.41s0.631 1.41 1.41 1.41zM10.978 14.165l-0.008-0.012c0 0 6.448-0.003 8.453 0.015-1.959-1.724-2.364-2.92-7.421-3.017l-1.214 0.019c-0.004 0.239-0.108 2.995 0.19 2.995zM6.376 14.164h3.358c0 0 0.084-2.903 0.090-2.936s-0.026-0.033-0.087-0.033c-2.708 0.273-4.211 2.969-3.361 2.969z"
    ></path>
  </svg>
))
export default SvgCar4
