'use client'

import './DayPickerStyles.css'

import { DateRange, DayPicker } from 'react-day-picker'
import { Locale, differenceInMonths, format, isBefore, startOfMonth } from 'date-fns'
import { memo, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import DatePickerLegend from './DatePickerLegend'
import { useTranslation } from 'react-i18next'
import { DatesData, focusElement, useWindowWidth } from './utils'
import clsx from 'clsx'

interface Props {
  locale: Locale | undefined
  selectedDates: DateRange
  outboundTravelDates: DatesData
  homeboundTravelDates?: DatesData
  newStyles?: boolean
  returnFocused: boolean
  isLoading: boolean
  outboundDateChanged: (value: Date) => void
  returnDateChanged: (value: Date) => void
  onClose: () => void
}

// All dates between todays date and the first available outbound dates are added to the disabled list.
function getCombinedDisabledHomeboundDates(outboundDates: DatesData, homeboundDates?: DatesData) {
  const currentDate = new Date()
  const combinedDisabledDates = [...(homeboundDates?.disabledDates || [])]

  while (outboundDates?.disabledDates?.includes(format(currentDate, 'yyyy-MM-dd'))) {
    const formattedCurrentDate = format(currentDate, 'yyyy-MM-dd')
    if (!homeboundDates?.disabledDates?.includes(formattedCurrentDate)) {
      combinedDisabledDates.push(formattedCurrentDate)
    }
    currentDate.setDate(currentDate.getDate() + 1)
  }
  return combinedDisabledDates?.map((date: any) => new Date(date))
}

const DateRangePickerInternal: React.FC<Props> = ({
  locale,
  selectedDates,
  outboundTravelDates,
  homeboundTravelDates,
  returnFocused,
  newStyles = false,
  isLoading,
  outboundDateChanged,
  returnDateChanged,
  onClose,
}) => {
  const { isMobile, isTablet } = useWindowWidth()
  const [numberOfMonth, setNumberOfMonth] = useState<number>(0)
  const [moreMonthsToLoad, setMoreMonthsToLoad] = useState<boolean>(true)
  const { ref, inView } = useInView({
    threshold: 0,
  })

  const { t } = useTranslation()

  useEffect(() => {
    setNumberOfMonth(isMobile || isTablet ? 6 : 2)
  }, [isMobile, isTablet])

  useEffect(() => {
    if (outboundTravelDates) {
      const difference = differenceInMonths(
        startOfMonth(new Date(outboundTravelDates.dates.toDate)),
        startOfMonth(new Date(outboundTravelDates.dates.fromDate))
      )

      if (inView && (isMobile || isTablet) && numberOfMonth < difference + 2) {
        setNumberOfMonth(numberOfMonth + 2)
      } else {
        if (!(numberOfMonth < difference + 2)) setMoreMonthsToLoad(false)
      }
    }
  }, [inView])

  useEffect(() => {
    if (isMobile) {
      focusElement('.rdp-day_selected')
    }
  }, [])

  const datesSelected = (dates: DateRange | undefined) => {
    // to be able to select outbound date in UI again
    if (!returnFocused && selectedDates.from && dates?.from === undefined) {
      return outboundDateChanged(selectedDates.from)
    }

    // Return date is clicked
    if (dates?.from && selectedDates.from && isBefore(dates?.from, selectedDates.from)) {
      outboundDateChanged(dates.from)
      return
    }

    if (!returnFocused) {
      const selectedOutboundDate = selectedDates.from

      // Date before selected outbound date was clicked
      if (dates?.from && selectedOutboundDate && isBefore(dates.from, selectedOutboundDate)) {
        outboundDateChanged(dates.from)
        return
      }

      // Date after selected outbound date was clicked
      if (dates?.to) {
        outboundDateChanged(dates.to)
        return
      }

      // Make it possible to click and selected the outbound date on the date where return is selected already.
      // This only works if date is not disabled for outbound
      if (!returnFocused && dates?.from !== undefined && dates.from === selectedDates.to) {
        outboundDateChanged(dates.from)
        return
      }
    }

    // Return date is clicked
    if (returnFocused) {
      dates?.to && returnDateChanged(dates?.to)
      outboundTravelDates.dates.toDate && onClose()
    }
  }

  const disabledDays =
    returnFocused && selectedDates.from
      ? getCombinedDisabledHomeboundDates(outboundTravelDates, homeboundTravelDates)
      : outboundTravelDates?.disabledDates?.map((date: any) => new Date(date))

  const offerDates =
    returnFocused && selectedDates.from
      ? (homeboundTravelDates?.offerDates?.map((x) => new Date(x)) as any)
      : (outboundTravelDates?.offerDates?.map((x) => new Date(x)) as any)

  return (
    <div id="datepickerContainer" className="tabletDown:ui-flex-col ui-z-10 ui-flex ui-bg-white">
      {outboundTravelDates && (
        <>
          <DayPicker
            locale={locale}
            className={clsx('rdp-range', newStyles ? 'rdp-new' : 'rdp-old')}
            fromDate={new Date(outboundTravelDates.dates.fromDate)}
            toDate={
              !isLoading && !returnFocused
                ? new Date(outboundTravelDates.dates.toDate)
                : homeboundTravelDates?.dates?.toDate
                  ? new Date(homeboundTravelDates.dates.toDate)
                  : new Date(outboundTravelDates.dates.toDate) // show outbound dates while homebound dates are loading
            }
            defaultMonth={(!isMobile && selectedDates.from) || new Date()}
            weekStartsOn={1}
            mode="range"
            numberOfMonths={numberOfMonth}
            pagedNavigation={true}
            disabled={
              returnFocused && !isLoading && !homeboundTravelDates?.dates?.toDate
                ? { after: new Date(Date.now() - 86400000) }
                : disabledDays
            }
            modifiers={{ offers: offerDates ? offerDates : false }}
            modifiersClassNames={{
              offers: 'rdp-offer',
            }}
            showOutsideDays={false}
            selected={selectedDates}
            onSelect={(dates) => datesSelected(dates)}
            fixedWeeks={true}
            showWeekNumber={true}
            styles={{
              caption_start: { paddingTop: outboundTravelDates?.offerText && isMobile ? '49px' : '0' },
              months: { paddingTop: outboundTravelDates?.offerText && !isMobile ? '48px' : '0' },
            }}
          />
          <DatePickerLegend offerText={outboundTravelDates?.offerText} />
        </>
      )}
      {/* Load more month when this div is hit when scrolling */}
      {(isMobile || isTablet) && (
        <div className="ui-dfds-label ui-mt-dfds-s ui-mb-dfds-l ui-flex ui-items-center ui-justify-center" ref={ref}>
          {moreMonthsToLoad ? t('SHOW-MORE-MONTHS') : t('NO-MORE-MONTHS-WITH-CAPACITY')}
        </div>
      )}
    </div>
  )
}
export const DateRangePicker = memo(DateRangePickerInternal)
