import { LoggingInterfaces } from '../logging-interfaces/logging.interface'

const PostLog = async (url: string, data: LoggingInterfaces.LogProps): Promise<void> => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    if (!response.ok) {
      console.error('Common error logging response not ok:', response)
    }
  } catch (error) {
    console.error('Common error logging catched error:', error)
  }
}

export const logger = (
  env: 'localhost' | 'dev' | 'prod' | 'test' | 'uat' | 'uat-2' | 'uat-3' | 'uat-4',
  data: LoggingInterfaces.LogProps
): void => {
  const enviroment = ensureEnvironment(env)
  const url = `https://api.hellman.oxygen.dfds.cloud/${enviroment}/logging-provider/api/passenger-ferries/log`
  PostLog(url, data)
  return
}

export const loggerAsync = async (
  env: 'localhost' | 'dev' | 'prod' | 'test' | 'uat' | 'uat-2' | 'uat-3' | 'uat-4',
  data: LoggingInterfaces.LogProps
): Promise<void> => {
  const enviroment = ensureEnvironment(env)
  const url = `https://api.hellman.oxygen.dfds.cloud/${enviroment}/logging-provider/api/passenger-ferries/log`
  return await PostLog(url, data)
}

const ensureEnvironment = (env: 'localhost' | 'dev' | 'prod' | 'test' | 'uat' | 'uat-2' | 'uat-3' | 'uat-4'): 'prod' | 'dev' => {
  if (env === 'prod') return 'prod'
  if (['localhost', 'dev', 'test', 'uat', 'uat-2', 'uat-3', 'uat-4'].includes(env)) return 'dev'
  throw new Error('Invalid environment')
}
